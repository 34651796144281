@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Titillium+Web:ital,wght@1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@400;700&family=Pacifico&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;


@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}