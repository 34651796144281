.stage-cube-cont {

    top: 0;
    padding-top: 30px;
    margin-left: 0;
    position: absolute;
    overflow: visible;
}
.cubespinner {
    animation-name: spincube;

    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 6s;
    transform-style: preserve-3d;
    transform: translateZ(20px);
}
.cubespinner div {
    position: absolute;
    width: 40px;
    height: 40px;
    background: rgba(12, 12, 12, 0.4);
    text-align: center;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 7px 0px rgb(185, 185, 129);
}
.cubespinner .face1 {
    transform: rotateY(0deg) translateZ(20px);
    color: green;
}
.cubespinner .face2 {
    transform: rotateY(90deg) translateZ(20px);
    color: #f54900;
}
.cubespinner .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(20px);
    color: #28a4d9;
}
.cubespinner .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(20px);
    color: #5ed4f4;
}
.cubespinner .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(20px);
    color: #0856bb;
}
.cubespinner .face6 {
    transform: rotateX(-90deg) translateZ(20px);
    color: #FFD43B;
}
@keyframes spincube {
    from, to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
   }
    16% {
        transform: rotateY(-90deg);
   }
    33% {
        transform: rotateY(-90deg) rotateZ(90deg);
   }
    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
   }
    66% {
        transform: rotateY(-270deg) rotateX(90deg);
   }
    83% {
        transform: rotateX(90deg);
   }
}

